<template>
  <div
    v-loading="loading"
    style="padding-top: 150px;"
  >
    <section>
      <article class="mt-5">
        <div class="container mt-5">
          <div class="row">
            <div class="col-md-6">
              <h2>Your Reservation</h2>
              <hr />
              <!-- {{ roomDetails }}<br/> -->
              <p style="font-weight: 600; font-size: 1.2em;">
                {{ roomDetails.room_name }} - {{ roomDetails.number_of_rooms }} room
              </p>
              <p>
                Check-In Date:
                <span style="font-weight: 600;">
                  {{ formattedDate(roomDetails.check_in_date) }}
                </span><br />
                Check-Out Date:
                <span style="font-weight: 600;">
                  {{ formattedDate(roomDetails.check_out_date) }}
                </span>
              </p>
              <p>
                <span>{{ nights }} Nights. </span><br />
                <span>
                  <i class="el-icon-user-solid"></i>
                  {{roomDetails.number_adult_guest}} Adults
                  <i class="el-icon-user-solid"></i>
                  {{roomDetails.number_children_guest}} Children
                </span>
              </p>
              <p>
                Total:
                <span style="font-weight: 600;">
                  $ {{ formatNumber(roomDetails.room_price) }}
                </span>
              </p>
            </div>
            <div class="col-md-6">
              <h2>Guest Information</h2>

              <el-form
                :model="reservationForm"
                :rules="rules"
                label-position="top"
                ref="reservationForm"
                label-width="120px"
                class="demo-reservationForm"
              >
                <div class="row">
                  <div class="col-md-6">
                    <el-form-item
                      label="First name"
                      prop="first_name"
                    >
                      <el-input v-model="reservationForm.first_name"></el-input>
                    </el-form-item>
                  </div>

                  <div class="col-md-6">
                    <el-form-item
                      label="Last Name"
                      prop="last_name"
                    >
                      <el-input v-model="reservationForm.last_name"></el-input>
                    </el-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <el-form-item
                      label="Email"
                      prop="email"
                    >
                      <el-input v-model="reservationForm.email"></el-input>
                    </el-form-item>
                  </div>

                  <div class="col-md-6">
                    <el-form-item
                      label="Phone Number"
                      prop="phone_number"
                    >
                      <el-input v-model="reservationForm.phone_number"></el-input>
                    </el-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <el-form-item
                      label="Address"
                      prop="address"
                    >
                      <el-input v-model="reservationForm.address"></el-input>
                    </el-form-item>
                  </div>

                  <div class="col-md-6">
                    <el-form-item
                      label="Country"
                      prop="country"
                    >
                      <el-input v-model="reservationForm.country"></el-input>
                    </el-form-item>
                  </div>
                </div>

                <div class="row mt-4 mb-5">
                  <div class="col-md-12">
                    <el-form-item>
                      <el-button
                        style="width: 100%; background-color: #0E763C; color: white"
                        @click="submitForm('reservationForm')"
                      >Proceed to Payment <i class="el-icon-right"></i></el-button>
                    </el-form-item>
                  </div>
                </div>
              </el-form>
            </div>
          </div>
        </div>
      </article>
    </section>
  </div>
</template>

<script>
import { format, parseISO } from "date-fns";
export default {
  data() {
    return {
      loading: false,
      reservationForm: {
        first_name: "",
        last_name: "",
        phone_number: "",
        email: "",
        address: "",
        country: "",
        room_name: "",
        room_code: "",
        check_in_date: "",
        check_out_date: "",
        amount: "",
        number_of_rooms: "",
        number_children_guest: "",
        number_adult_guest: "",
      },
      rules: {
        first_name: [
          {
            required: true,
            message: "Please input First Name",
            trigger: "blur",
          },
        ],
        last_name: [
          {
            required: true,
            message: "Please input Last Name",
            trigger: "blur",
          },
        ],
        phone_number: [
          {
            required: true,
            message: "Please input phone_number",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "Please input email",
            trigger: "blur",
          },
        ],
        address: [
          {
            required: true,
            message: "Please input Address",
            trigger: "blur",
          },
        ],
        country: [
          {
            required: true,
            message: "Please input Country",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    roomDetails() {
      return JSON.parse(localStorage.getItem("room_details"));
    },
    nights() {
      const date1 = new Date(`${this.roomDetails.check_in_date}`);
      const date2 = new Date(`${this.roomDetails.check_out_date}`);
      const absDate1 = Math.abs(date1);
      const absDate2 = Math.abs(date2);
      const ceilDate1 = Math.ceil(absDate1 / (1000 * 60 * 60 * 24));
      const ceilDate2 = Math.ceil(+absDate2 / (1000 * 60 * 60 * 24));
      const diffDays = ceilDate2 - ceilDate1;
      return diffDays;
    },

    formattedDate() {
      return (dateString) => format(parseISO(dateString), "do MMMM yyyy");
    },
  },

  methods: {
    handleClose(done) {
      done();
    },
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },

    async submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.loading = true;
          this.reservationForm.room_name = this.roomDetails.room_name;
          this.reservationForm.room_code = this.roomDetails.room_code;
          this.reservationForm.check_in_date = this.roomDetails.check_in_date;
          this.reservationForm.check_out_date = this.roomDetails.check_out_date;
          this.reservationForm.amount = this.roomDetails.room_price;
          this.reservationForm.number_of_rooms =
            this.roomDetails.number_of_rooms;
          this.reservationForm.number_children_guest =
            this.roomDetails.number_children_guest;
          this.reservationForm.number_adult_guest =
            this.roomDetails.number_adult_guest;

          try {
            let request = await this.$http.post(
              `bookings/add`,
              this.reservationForm
            );
            if (request.data.success && request.data.message == "INITIATED") {
              let redirectUrl = request.data.card_processor;
              window.location.replace(redirectUrl);
            } else {
              throw "UNEXPECTED_RESPONSE";
            }
          } catch (error) {
            if (error.message == "Network Error") {
              return this.$notify.error({
                title: "Connection failed",
                message: "A network error occured, please try again.",
              });
            }
            this.$notify.error({
              title: "Loading Failed",
              message:
                "Unable to place a reservation now, Please try again later",
            });
          } finally {
            this.loading = false;
          }
        } else {
          this.$notify.error({
            title: "Error",
            message: "Unable to place a reservation.",
          });
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style>
.el-form-item {
  margin-bottom: 0px;
}

.el-form--label-top .el-form-item__label {
  float: none;
  display: inline-block;
  text-align: left;
  padding: 0 0 0px;
}
label {
  display: inline-block;
  margin-bottom: 0px;
}
</style>